<template>
  <div class="body1">
    <a-breadcrumb>
      <a-breadcrumb-item>
        <router-link :to="{name: 'home'}">{{ $t('home') }}</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        {{ $t('booking_list') }}
      </a-breadcrumb-item>
    </a-breadcrumb>
    <div class="border search-form">
      <a-form :colon="false" :hideRequiredMark="true" :model="searchFormState" autocomplete="off" :scrollToFirstError="true" layout="inline">
        <a-row style="width: 100%">
          <a-col :span="24">
            <a-radio-group v-model:value="searchFormState.dateType" :options="dateTypeOptions">
            </a-radio-group>
          </a-col>
        </a-row>
        <a-divider/>
        <div style="width: 100%;padding: 0 10px">
          <a-row style="width: 100%">
          <a-col :span="8">
            <a-form-item name="dates" :label="dateTypeOptions.filter(item => item.value === searchFormState.dateType)[0].label">
              <a-range-picker v-model:value="searchFormState.dates" format="YYYY-MM-DD" :disabled="searchFormState.dateType === 0" style="width:100%" :inputReadOnly="true" :allowClear="false" />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item name="booking_id" :label="$t('booking_id')">
              <a-input v-model:value="searchFormState.orderId" :placeholder="'Jidu ' + $t('booking_id')" />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item name="other_booking_no" :label="$t('other_booking_no')">
              <a-input v-model:value="searchFormState.otherBookingNo" :placeholder="$t('group_booking_id') + ' / ' + $t('client_ref_id')" />
            </a-form-item>
          </a-col>
        </a-row>
          <a-row style="width: 100%;margin-top: 20px;">
          <a-col :span="8">
            <a-form-item name="order_status" :label="$t('booking_status')">
              <a-select v-model:value="searchFormState.orderStatus" :options="orderStatusOptions">
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item name="payment_method" :label="$t('payment_method')">
              <a-select v-model:value="searchFormState.payWay" :options="paymentWayOptions">
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item name="hotel_name" :label="$t('hotel_name')">
              <a-input v-model:value="searchFormState.hotelName" :placeholder="$t('hotel_name')" />
            </a-form-item>
          </a-col>
        </a-row>
          <a-row style="width: 100%;margin-top: 20px;">
          <a-col :span="8">
            <a-form-item name="contact_person" :label="$t('contact_person')">
              <a-input v-model:value="searchFormState.contactPerson" :placeholder="$t('contact_person') + ' / Email / ' + $t('contact_tel')" />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item name="guest_name" :label="$t('guest_name')">
              <a-input v-model:value="searchFormState.guestName" :placeholder="$t('guest_name')" />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item style="float: right">
              <a-button type="primary" :loading="loading" @click="onSearch">{{ $t('search') }}</a-button>
              <a-button style="margin-left: 15px" @click="onReset">{{ $t('reset') }}</a-button>
            </a-form-item>
          </a-col>
        </a-row>
        </div>
      </a-form>
    </div>
    <div class="border order-list" v-if="!loading">
      <div style="display: flex;justify-content: space-between;">
        <div>
          {{ $t('total_orders', { s: dataPaging.Total })}}
        </div>
        <div>
          <a-radio-group v-model:value="showMode" button-style="solid" size="small">
            <a-radio-button value="list">{{ $t('list_mode') }}</a-radio-button>
            <a-radio-button value="card">{{ $t('card_mode') }}</a-radio-button>
          </a-radio-group>
        </div>
      </div>
      <a-divider/>
      <div class="list-mode" v-if="showMode === 'list'">
        <table>
          <thead>
            <tr>
              <th>
                {{ $t('booking_id') }}<br />
                {{ $t('hotel_confirm_no') }}
              </th>
              <th>
                {{ $t('client_ref_id') }}<br />
                {{ $t('group_booking_id') }}
              </th>
              <th style="width: 28%">
                {{ $t('hotel_name') }}<br />
                {{ $t('room_type') }}<br />
                {{ $t('meal_type') }}
              </th>
              <th>
                {{$t('checkin_date')}}<br />
                {{$t('checkout_date')}}
              </th>
              <th style="width: 11%">
                {{$t('total_price')}}
                <template v-if="!is_eps_test"><br />
                  {{$t('cancel_date')}}</template>
              </th>
              <th style="width: 11%">
                {{$t('booking_status')}}<br/>
                {{$t('payment_method')}}<br/>
                {{$t('latest_payment_time')}}
              </th>
              <th style="width: 11%">
                {{ $t('booking_time') }}<br/>
                {{ $t('opt_account') }}
              </th>
            </tr>
          </thead>
          <tbody>
          <template v-for="(orderInfo,index) in dataPaging.Rows" v-bind:key="index">
            <tr :class="(orderInfo.order_status <= OrderHotelStatus.Canceled && (orderInfo.cancelled_fee === 0 || orderInfo.cancelled_fee === undefined)) ? 'grey_order':''">
              <td>
                <router-link :to="{name:'booking_detail', params:{ OrderId: orderInfo.order_id }}" target="_blank">{{ orderInfo.order_id }}</router-link> <br />
                {{ getEmptyValue(orderInfo.confirm_no, isZhCn) }}
              </td>
              <td>
                {{ getEmptyValue(orderInfo.source_id == orderInfo.order_id ? '': orderInfo.source_id, isZhCn) }} <br />
                {{ getEmptyValue(orderInfo.source_id1, isZhCn) }}
              </td>
              <td>
                <router-link :to="{name:'hotel_detail', params:{HotelId: orderInfo.hotel_id}}" target="_blank">
                  <template v-if="isZhCn">
                    {{
                      isEmpty(orderInfo.hotel_name_cn) ? orderInfo.hotel_name_en : (orderInfo.hotel_name_cn + ' (' + orderInfo.hotel_name_en + ')')
                    }}
                  </template>
                  <template v-else>
                    {{ orderInfo.hotel_name_en }}
                  </template>
                </router-link> <br />
                <template v-if="orderInfo.room_id > 0">
                  {{ isZhCn ? getNameCnEn(orderInfo.room_name_cn, orderInfo.room_name_en) : orderInfo.room_name_en }}
                </template>
                <template v-else>
                  {{ orderInfo.room_name }}
                </template><br />
                {{ $t('mealtype_unit.' + orderInfo.board_code, {n: orderInfo.PaxRoom.Count * orderInfo.PaxRoom.Adults}) }}
              </td>
              <td>
                {{ dayjs.unix(orderInfo.checkin).format(checkInOutFormat) }}<br />
                {{ dayjs.unix(orderInfo.checkout).format(checkInOutFormat) }}<br />
                ({{ $t('room_unit', {n: orderInfo.PaxRoom.Count}) }} {{ $t('night_unit', {'n': dayjs.unix(orderInfo.checkout).diff(dayjs.unix(orderInfo.checkin), 'days')}) }})
              </td>
              <td>
                <template v-if="orderInfo.order_status === OrderHotelStatus.Fail">
                  {{ orderInfo.curcode }} 0
                </template>
                <template v-else-if="orderInfo.order_status === OrderHotelStatus.Canceled">
                  {{ orderInfo.curcode }} {{ $filters.formatAmount(orderInfo.cancelled_fee, 0) }}&nbsp;
                  <a-tooltip :color="'#ffffff'" placement="topRight" v-if="orderInfo.cancelled_fee > 0">
                    <template #title>
                      <div style="color: #000000;">{{ $t('cancelled_charge_remark', { pri: orderInfo.curcode + ' ' + $filters.formatAmount(orderInfo.cancelled_fee, 0)}) }}</div>
                    </template>
                    <QuestionCircleFilled class="warning-color" />
                  </a-tooltip>
                </template>
                <template v-else>
                  {{ orderInfo.curcode }} {{ $filters.formatAmount(orderInfo.price, 0) }}
                </template>
                <template v-if="!is_eps_test">
                  <br />
                  <template v-if="!isRefundable(orderInfo.cancel_hour, dayjs.unix(orderInfo.checkin), dayjs.unix(orderInfo.order_date))">
                    <span class="warning-color">{{ $t('non_refundable') }}</span>
                  </template>
                  <template v-else>
                      {{ getCancelDatetime(dayjs.unix(orderInfo.checkin), orderInfo.cancel_hour, 'YYYY-MM-DD HH:mm:ss') }}
                  </template>
                </template>
              </td>
              <td>
                <order-hotel-status-tag :order-status="orderInfo.order_status"/><br />
                <template v-if="!isEmpty(orderInfo.pay_way)">{{ $t('payment_way.' + orderInfo.pay_way) }}</template><template v-else>{{ getEmptyValue(orderInfo.payment_way)}}</template><br v-if="orderInfo.last_pay_time" />
                <span class="error-color" v-if="orderInfo.last_pay_time">
                  {{ dayjs.unix(orderInfo.last_pay_time).format('YYYY-MM-DD HH:mm:ss') }}
                </span>
              </td>
              <td>
                {{ dayjs.unix(orderInfo.order_date).format('YYYY-MM-DD HH:mm:ss') }}<br/>
                {{ isEmpty(orderInfo.source_channel)?'API':orderInfo.source_channel }}<br />
                <template v-if="!orderInfo.HasTicket">
                  <a @click="showAddTicket(orderInfo)" v-if="orderInfo.order_status === OrderHotelStatus.Success">{{ $ta('submit|work_order', 'p') }}</a>
                </template>
                <template v-else>
                  <a @click="showAddTicket(orderInfo)" v-if="orderInfo.order_status === OrderHotelStatus.Success">{{ $ta('append|work_order', 'p') }}</a>
                  <a style="margin-left: 5px" @click="showTicketLog(orderInfo)">{{ $t('work_order_log') }}</a>
                </template>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
      </div>
      <div class="card-mode" v-if="showMode === 'card'">
        <template v-for="(orderInfo,index) in dataPaging.Rows" v-bind:key="index">
          <table :class="(orderInfo.order_status <= OrderHotelStatus.Canceled && (orderInfo.cancelled_fee === 0 || orderInfo.cancelled_fee === undefined)) ? 'grey_order':''">
            <thead>
            <tr>
              <th colspan="4">
                <div style="float: left">
                  <span style="margin-right:20px">
                    <router-link :to="{name:'booking_detail', params:{ OrderId: orderInfo.order_id }}" target="_blank">{{ orderInfo.order_id }}</router-link>
                    <template v-if="!orderInfo.HasTicket">
                      <a style="padding-left: 10px" @click="showAddTicket(orderInfo)" v-if="orderInfo.order_status === OrderHotelStatus.Success">{{ $ta('submit|work_order', 'p') }}</a>
                    </template>
                    <template v-else>
                      <a style="padding-left: 10px" @click="showAddTicket(orderInfo)" v-if="orderInfo.order_status === OrderHotelStatus.Success">{{ $ta('submit|work_order', 'p') }}</a>
                      <a style="padding-left: 10px" @click="showTicketLog(orderInfo)">{{ $t('work_order_log') }}</a>
                    </template>
                  </span>
                  <order-hotel-status-tag :order-status="orderInfo.order_status"/>
                </div>
                <div style="float: right">
                  {{ $t('total_price')}}:
                  <template v-if="orderInfo.order_status === OrderHotelStatus.Fail">
                    <strong class="warning-color">{{ orderInfo.curcode }} 0</strong>
                  </template>
                  <template v-else-if="orderInfo.order_status === OrderHotelStatus.Canceled">
                    <strong class="warning-color">{{ orderInfo.curcode }} {{ $filters.formatAmount(orderInfo.cancelled_fee, 0) }}</strong>&nbsp;
                    <a-tooltip :color="'#ffffff'" placement="topRight">
                      <template #title>
                        <div style="color: #000000;" v-if="orderInfo.cancelled_fee === 0">{{ $t('cancelled_free_remark') }}</div>
                        <div style="color: #000000;" v-else-if="orderInfo.cancelled_fee > 0">{{ $t('cancelled_charge_remark', { pri: orderInfo.curcode + ' ' + $filters.formatAmount(orderInfo.cancelled_fee, 0)}) }}</div>
                        <div style="color: #000000;" v-else>{{ $t('cancelled_unknow_remark') }}</div>
                      </template>
                      <QuestionCircleFilled class="warning-color" />
                    </a-tooltip>
                  </template>
                  <template v-else>
                    <strong class="warning-color">{{ orderInfo.curcode }} {{ $filters.formatAmount(orderInfo.price, 0) }}</strong>
                  </template>
                </div>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>
                <div class="label">{{$t('checkin_date')}} ~ {{$t('checkout_date')}}</div>
                {{ dayjs.unix(orderInfo.checkin).format(checkInOutFormat) }} ~ {{ dayjs.unix(orderInfo.checkout).format(checkInOutFormat) }}
                ({{ $t('night_unit', {'n': dayjs.unix(orderInfo.checkout).diff(dayjs.unix(orderInfo.checkin), 'days')}) }})
              </td>
              <td>
                <div class="label">{{$t('hotel_confirm_no')}}</div>
                {{ getEmptyValue(orderInfo.confirm_no,isZhCn) }}
              </td>
              <td><template v-if="!is_eps_test">
                <div class="label">{{$t('cancel_date')}}</div>
                <template v-if="!isRefundable(orderInfo.cancel_hour, dayjs.unix(orderInfo.checkin), dayjs.unix(orderInfo.order_date))">
                  <span class="warning-color">{{ $t('non_refundable') }}</span>
                </template>
                <template v-else>
                  {{ getCancelDatetime(dayjs.unix(orderInfo.checkin), orderInfo.cancel_hour, 'YYYY-MM-DD HH:mm:ss') }}
                </template>
              </template>
              </td>
              <td>
                <div class="label">{{$t('booking_time')}}</div>
                {{ dayjs.unix(orderInfo.order_date).format('YYYY-MM-DD HH:mm:ss') }}
              </td>
            </tr>
            <tr>
              <td rowspan="2">
                <router-link :to="{name:'hotel_detail', params:{HotelId: orderInfo.hotel_id}}" target="_blank">
                  <template v-if="isZhCn">
                    {{
                      isEmpty(orderInfo.hotel_name_cn) ? orderInfo.hotel_name_en : (orderInfo.hotel_name_cn + ' (' + orderInfo.hotel_name_en + ')')
                    }}
                  </template>
                  <template v-else>
                    {{ orderInfo.hotel_name_en }}
                  </template>
                </router-link> <br />
                <template v-if="orderInfo.room_id > 0">
                  {{ isZhCn ? getNameCnEn(orderInfo.room_name_cn, orderInfo.room_name_en) : orderInfo.room_name_en }}
                </template>
                <template v-else>
                  {{ orderInfo.room_name }}
                </template> ({{ $t('room_unit', {n: orderInfo.PaxRoom.Count}) }})<br />
                {{ $t('mealtype_unit.' + orderInfo.board_code, {n: orderInfo.PaxRoom.Count * orderInfo.PaxRoom.Adults}) }}
              </td>
              <td>
                <div class="label">{{$t('payment_method')}}<span class="error-color" v-if="orderInfo.last_pay_time"> / {{$t('latest_payment_time')}}</span></div>
                <template v-if="!isEmpty(orderInfo.pay_way)">{{ $t('payment_way.' + orderInfo.pay_way) }}</template><template v-else>{{ getEmptyValue(orderInfo.payment_way)}}</template>
                <span class="error-color" v-if="orderInfo.last_pay_time">
                   / {{ dayjs.unix(orderInfo.last_pay_time).format('YYYY-MM-DD HH:mm:ss') }}
                </span>
              </td>
              <td>
                <div class="label">{{$t('client_ref_id')}} / {{$t('group_booking_id')}}</div>
                {{ getEmptyValue(orderInfo.source_id == orderInfo.order_id ? '': orderInfo.source_id, isZhCn) }} / {{ getEmptyValue(orderInfo.source_id1, isZhCn) }}
              </td>
              <td rowspan="2">
                <div class="label">{{$t('nationality')}} / {{$t('per_room')}}</div>
                {{ getCountryName(orderInfo.PaxRoom.Nationality) }} /
                {{ $t('adult_unit', {'n': orderInfo.PaxRoom.Adults}) }},
                {{ $t('child_unit', {'n': orderInfo.PaxRoom?.Children?.length}) }}
                <template v-if="orderInfo.PaxRoom?.Children?.length > 1">
                  (<template v-for="(age,index) in orderInfo.PaxRoom.Children" v-bind:key="index"><template v-if="index > 0">, </template>{{ $t('year_old', {n: age}) }}</template>)
                </template>
                <div class="label">{{$t('guest_name')}}</div>
                <template v-for="(name,nameIndex) in orderInfo.PaxRoom.PaxNames" v-bind:key="nameIndex">
                  {{name.LastName}} {{name.FirstName}};
                </template>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <div class="label">{{$t('contact_person')}} / Email / {{$t('contact_tel')}}</div>
                {{ orderInfo.contact_name }} / {{ orderInfo.contact_email }} / {{ orderInfo.contact_phone }}
              </td>
            </tr>
            </tbody>
          </table>
        </template>
      </div>
      <div style="display: flex;justify-content: space-between;">
        <div>
        </div>
        <div>
          <a-pagination v-model:current="pageIndex" :total="dataPaging.Total" :showSizeChanger="false" :showQuickJumper="true" :hideOnSinglePage="true" :show-total="total => $t('total_items', {s: total})"/>
        </div>
      </div>
      <a-empty v-if="!loading && dataPaging.Rows.length <= 0" style="width:100%;padding: 50px 0px" />
    </div>
    <div class="border order-list" v-else>
      <a-spin :spinning="loading" size="large" style="width:100%;padding: 50px 0px">
      </a-spin>
    </div>
  </div>


  <a-modal v-model:visible="addTicketVisible" :maskClosable="false" :title="$ta('create|work_order', 'p')" :width="950" @ok="submitTicket" @cancel="cancelTicket" :ok-button-props="submitTicketProps" :confirmLoading="createTicketLoading">
    <div style="display: flex;align-items : center;flex-direction:column;justify-content: center;">
      <a-spin v-if="ticketTypeLoading" :spinning="ticketTypeLoading" size="large" style="width:100%;padding: 50px 0px">
      </a-spin>
      <table class="add_ticket" v-else>
        <tr>
          <td>
            <a-radio-group option-type="button" button-style="solid" v-model:value="workOrder.ticketTypeGroup" :options="ticketTypeGroupOptions" @change="ticketTypeGroupChange">
            </a-radio-group>
          </td>
        </tr>
        <tr>
          <th>{{ $t('problem_type') }}</th>
        </tr>
        <tr>
          <td>
            <a-radio-group option-type="button" button-style="solid" size="small"  v-model:value="workOrder.ticketType" @change="ticketTypeChange" :options="ticketTypeOptions">
            </a-radio-group>
          </td>
        </tr>
        <template v-if="isZhCn">
          <template v-for="(tip,index) in selectedTicketType?.ticket_type_tips_cn" v-bind:key="index">
            <tr>
              <td>
                <span class="tip warning-color">{{ tip }}</span>
              </td>
            </tr>
          </template>
        </template>
        <template v-else>
          <template v-for="(tip,index) in selectedTicketType?.ticket_type_tips_en" v-bind:key="index">
            <tr>
              <td>
                <span class="tip warning-color">{{ tip }}</span>
              </td>
            </tr>
          </template>
        </template>
        <template v-if="selectedTicketType?.ticket_type_name_cn == '确认号' && !isEmpty(ticketOrderInfo.confirm_no)">
          <tr>
            <th>{{ $t('content') }}</th>
          </tr>
          <tr>
            <td>
              您的订单已确认，确认号为 <span class="warning-color">{{ ticketOrderInfo.confirm_no }}</span>
            </td>
          </tr>
        </template>
        <template v-for="(control,index) in selectedTicketType?.ticket_type_control" v-bind:key="index">
          <template v-if="control.Name === 'special_request' && control.IsVisible">
            <tr>
              <td>
                <special-request-picker :checkin="ticketOrderInfo.checkin" @valueChange="specialRequestChange"></special-request-picker>
              </td>
            </tr>
          </template>
          <template v-if="control.Name === 'issue_select' && control.IsVisible">
            <tr>
              <td>
                <a-radio-group v-model:value="workOrder.issue_select" :options="issueSelectOptions" style="width: 100%">
                </a-radio-group>
              </td>
            </tr>
          </template>
          <template v-if="control.Name === 'stay_info' && control.IsVisible">
            <tr>
              <td>
                <stay-info-picker :checkin="ticketOrderInfo.checkin" :checkout="ticketOrderInfo.checkout" @valueChange="stayInfoChange"></stay-info-picker>
              </td>
            </tr>
          </template>
          <template v-if="control.Name === 'content' && control.IsVisible">
            <tr>
              <th>{{ $t('content') }}</th>
            </tr>
            <tr>
              <td>
                <a-textarea v-model:value="workOrder.content" :auto-size="{ minRows: 2, maxRows: 5 }" />
              </td>
            </tr>
          </template>
          <template v-if="control.Name === 'remark' && control.IsVisible">
            <tr>
              <th>{{ $t('remark') }}</th>
            </tr>
            <tr>
              <td>
                <a-textarea v-model:value="workOrder.remark" :auto-size="{ minRows: 2, maxRows: 5 }" />
              </td>
            </tr>
          </template>
          <template v-if="control.Name === 'appendix' && control.IsVisible">
            <tr>
              <th>{{ $t('appendix') }}</th>
            </tr>
            <tr>
              <td>
                <a-upload v-model:file-list="workOrder.appendix" list-type="picture-card" :multiple="true" :customRequest="uploadFile">
                  <div v-if="workOrder.appendix.length < 8">
                    <loading-outlined v-if="uploadLoading"></loading-outlined>
                    <plus-outlined v-else></plus-outlined>
                    <div class="ant-upload-text">{{ $t('upload') }}</div>
                  </div>
                </a-upload>
              </td>
            </tr>
          </template>
        </template>
      </table>
    </div>
  </a-modal>

  <a-modal v-model:visible="ticketLogVisible" :maskClosable="false" :title="$t('work_order_log')" :width="950" :footer="null" :bodyStyle="{padding:'10px'}">
    <ticket-log :order_id="ticketOrderInfo?.order_id"></ticket-log>
  </a-modal>

</template>

<script>
import dayjs from "dayjs";
import {searchList} from "@/services/booking";
import {getTypes,updateFile,createTicket} from "@/services/ticket";
import {isEmpty, getNameCnEn, getEmptyValue, getNameCn, getCancelDatetime, isRefundable} from "@/utils/util";
import OrderHotelStatusTag from "@/views/booking/OrderHotelStatusTag";
import {AllCountries} from "@/data/country";
import {inject} from "vue";
import { OrderHotelStatus } from "@/data/order-hotel-status";
import { QuestionCircleFilled,PlusOutlined,LoadingOutlined } from '@ant-design/icons-vue';
import SpecialRequestPicker from "@/views/ticket/SpecialRequestPicker";
import StayInfoPicker from "@/views/ticket/StayInfoPicker";
import {Modal} from "ant-design-vue";
import TicketLog from "@/views/ticket/TicketLog";

export default {
  name: "BookingList",
  components: {
    TicketLog,
    StayInfoPicker,
    SpecialRequestPicker,
    OrderHotelStatusTag,
    QuestionCircleFilled,PlusOutlined,LoadingOutlined
  },
  data(){
    let order_hotel_status = this.$tm('order_hotel_status')
    let orderStatusOptions = [{ value: '', label: this.$t('all') }]
    for(let key in order_hotel_status) {
      if(key === '0')
        continue

      orderStatusOptions.push({ value: key, label: order_hotel_status[key] })
    }
    let payment_way = this.$tm('payment_way')
    let paymentWayOptions = [{ value: '', label: this.$t('all') }]
    for(let key in payment_way) {
      paymentWayOptions.push({ value: key, label: payment_way[key] })
    }

    let _ticket_type_group = this.$tm('ticket_type_group')
    let ticketTypeGroupOptions = []
    for(let key in _ticket_type_group) {
      ticketTypeGroupOptions.push({ value: parseInt(key), label: _ticket_type_group[key] })
    }

    let dateTypeOptions = [
      {value: 0, label: this.$t('all_date')},
      {value: 1, label: this.$t('booking_date')},
      {value: 2, label: this.$t('checkin_date')},
      {value: 3, label: this.$t('checkout_date')},
      {value: 4, label: this.$t('cancel_date')},
      {value: 5, label: this.$t('latest_payment_time')},
    ]
    let currentUser = this.$store.getters["account/getUser"]
    let is_eps_test = currentUser.is_eps_test
    if(is_eps_test){
      dateTypeOptions.splice(4, 1)
    }

    return {
      selectedMenuKeys: inject('selectedMenuKeys'),
      dayjs,
      OrderHotelStatus,
      checkInOutFormat: 'YYYY-MM-DD',
      isZhCn: this.$store.getters["setting/getLanguage"] === 'zh_CN',
      orderStatusOptions,
      paymentWayOptions,
      dateTypeOptions,
      searchFormState: {
        dateType: 0,
        dates: [dayjs().endOf("days"), dayjs().endOf("days")],
        orderId: '',
        otherBookingNo: '',
        orderStatus: '',
        payWay: '',
        contactPerson: '',
        guestName: '',
        hotelName: '',
      },
      loading: false,
      dataPaging: {PageIndex: 1, PageTotal: 0, PageSize: 10, Total: 0, Rows: []},
      pageIndex: 1,
      pageSize: 10,
      showMode: 'list',
      addTicketVisible: false,
      ticketTypeLoading: false,
      ticketTypes: [],
      ticketTypeOptions: [],
      ticketTypeGroupOptions,
      ticketOrderInfo: null,
      workOrder: {
        ticketTypeGroup: 1,
        ticketType: null,
        appendix: [],
        special_request: null,
        stay_info: null
      },
      selectedTicketType: null,
      uploadLoading: false,
      createTicketLoading: false,
      ticketLogVisible: false,
      is_eps_test
    }
  },
  computed: {
    submitTicketProps() {
      let disabled = false
      if (this.selectedTicketType === null) {
        disabled = true
      } else {
        if(this.selectedTicketType.ticket_type_name_cn == '确认号' && !isEmpty(this.ticketOrderInfo.confirm_no)){
          return { disabled: true }
        }

        this.selectedTicketType.ticket_type_control.forEach(item => {
          if(item.IsRequired) {
            console.log(item.Name, this.workOrder[item.Name])
            if(isEmpty(this.workOrder[item.Name]) || this.workOrder[item.Name].length === 0){
              disabled = true
              return
            }
          }
        })
      }
      return {disabled: disabled}
    },
    issueSelectOptions(){
      let children = this.ticketTypes.filter(item => { return item.ticket_type_pid === this.selectedTicketType.ticket_type_id })
      let options = []
      children.forEach(item => {
        if(this.isZhCn){
          options.push({value: item.ticket_type_name_cn, label: item.ticket_type_name_cn})
        }else{
          options.push({value: item.ticket_type_name_en, label: item.ticket_type_name_en})
        }
      })
      return options
    }
  },
  mounted() {
    this.selectedMenuKeys = ['booking_list']
    this.search()
  },
  watch:{
    pageIndex: function (value, value1) {
      if(value === value1)
        return

      this.search()
    }
  },
  methods:{
    isEmpty, getNameCnEn, getEmptyValue, getNameCn, getCancelDatetime, isRefundable,
    getCountryName(countryCode){
      let country = AllCountries.filter(item => item.code === countryCode)
      if(country)
        return this.isZhCn ? country[0].name_cn: country[0].name_en
      else
        return countryCode
    },
    onSearch(){
      this.pageIndex = 1
      this.search()
    },
    search(){
      let params = {}
      Object.assign(params, this.searchFormState)
      params.dates = [this.searchFormState.dates[0].format('YYYY-MM-DD'), this.searchFormState.dates[1].format('YYYY-MM-DD')]
      params.PageIndex = this.pageIndex
      params.PageSize = this.pageSize

      this.loading = true
      searchList(params).then(res => {
        this.loading = false
        const resData = res.data
        if (resData.Success) {
          resData.Data.Rows.forEach(item =>{
            item.PaxRoom = JSON.parse(item.pax_room_json)
            if(!item.PaxRoom.Children)
              item.PaxRoom.Children = []
          })
          this.dataPaging = resData.Data
        } else {
          console.log(this.$t('error.' + resData.Code))
        }
      }).catch(error => {
        console.log(error)
      })
    },
    onReset(){
      this.searchFormState = {
        dateType: 0,
        dates: [dayjs().endOf("days"), dayjs().endOf("days")],
        orderId: '',
        otherBookingNo: '',
        orderStatus: '',
        payWay: '',
        contactPerson: '',
        guestName: '',
        hotelName: '',
      }
    },
    showAddTicket: function (orderInfo) {
      this.ticketOrderInfo = orderInfo

      let now = dayjs(new Date())
      let checkin = dayjs.unix(orderInfo.checkin).add(12, 'hour')
      let checkout = dayjs.unix(orderInfo.checkout).add(12, 'hour')
      if(now.isAfter(checkin) && now.isBefore(checkout)){
        this.workOrder.ticketTypeGroup = 2
      }else if(now.isAfter(checkout)){
        this.workOrder.ticketTypeGroup = 3
      }

      this.addTicketVisible = true
      this.ticketTypeLoading = true
      getTypes().then(res => {
        this.ticketTypeLoading = false
        const resData = res.data
        if (resData.Success) {
          this.ticketTypes = resData.Data
          this.ticketTypes.forEach(item =>{
            item.ticket_type_tips_cn = eval(item.ticket_type_tips_cn)
            item.ticket_type_tips_en = eval(item.ticket_type_tips_en)
            item.ticket_type_control = eval(item.ticket_type_control)
          })
          this.ticketTypeGroupChange()
        } else {
          console.log(this.$t('error.' + resData.Code))
        }
      }).catch(error => {
        console.log(error)
      })
    },
    showTicketLog: function (orderInfo){
      this.ticketOrderInfo = orderInfo
      this.ticketLogVisible = true
    },
    ticketTypeGroupChange: function(){
      console.log(this.workOrder.ticketTypeGroup)
      let _options = this.ticketTypes.filter((item) => {
        return item.ticket_type_group === this.workOrder.ticketTypeGroup
      })
      this.ticketTypeOptions = []
      _options.forEach(item =>{
        this.ticketTypeOptions.push({value: item.ticket_type_id, label: this.isZhCn ? item.ticket_type_name_cn : item.ticket_type_name_en})
      })
      this.workOrder.ticketType = null
      this.selectedTicketType = null
    },
    ticketTypeChange: function(){
      this.selectedTicketType = this.ticketTypes.filter(item => { return item.ticket_type_id === this.workOrder.ticketType })[0]
    },
    uploadFile: function(data) {
      let formData = new FormData()
      formData.append('file', data.file)
      this.uploadLoading = true
      updateFile(formData).then((res) => {
        this.uploadLoading = false
        const resData = res.data
        if (resData.Success) {
          let file = this.workOrder.appendix.filter(item => { return item.uid === data.file.uid })[0]
          file.url = resData.Data
          file.status = 'done'
        } else {
          console.log(this.$t('error.' + resData.Code))
        }
      })
      return false
    },
    submitTicket: function() {
      let params = {};
      params.order_id = this.ticketOrderInfo.order_id;
      params.ticket_type_id = this.selectedTicketType.ticket_type_id;
      params.ticket_remark = this.workOrder.remark;
      params.ticket_appendix = [];
      this.workOrder.appendix.forEach(item => {
        params.ticket_appendix.push(item.url)
      })
      console.log(params)
      console.log(this.workOrder)

      if(!isEmpty(this.workOrder.issue_select))
        params.ticket_content = '[' + this.workOrder.issue_select + ']';

      if(!isEmpty(this.workOrder.issue_select) && !isEmpty(this.workOrder.stay_info)) {
        params.ticket_content = this.$t('because') + ' [' + this.workOrder.issue_select + '], ' + this.workOrder.stay_info;
      }

      if(!isEmpty(this.workOrder.special_request))
        params.ticket_content = this.workOrder.special_request;

      if(!isEmpty(this.workOrder.content))
        params.ticket_content = this.workOrder.content;

      this.createTicketLoading = true
      let _this = this
      createTicket(params).then(res => {
        this.createTicketLoading = false
        const resData = res.data
        if (resData.Success) {
          Modal.success({
            centered: true,
            title: this.$ta('create|success'),
            onOk: function(){
              _this.addTicketVisible = false
              _this.cancelTicket()
              _this.search()
            }
          });
        } else {
          Modal.error({
            centered: true,
            title: this.$t('error.' + resData.Code),
          });
        }
      }).catch(error => {
        console.log(error)
      })
    },
    cancelTicket: function() {
      this.workOrder.ticketType = null
      this.workOrder.ticketTypeGroup = 1
      this.selectedTicketType = null
      this.workOrder.appendix = []
      this.workOrder.issue_select = null
      this.workOrder.content = null
      this.workOrder.remark = null
      this.workOrder.special_request = null
      this.ticketOrderInfo = null
    },
    specialRequestChange(sRarr) {
      if(isEmpty(sRarr))
        return

      this.workOrder.special_request = sRarr.join(', ')
    },
    stayInfoChange(stayInfo) {
      this.workOrder.stay_info = stayInfo
    }
  }
}
</script>

<style scoped>
.search-form {
  padding: 15px;
  margin-top: 20px;
}
.search-form .ant-divider-horizontal {
  margin: 15px 0;
}
.order-list .ant-divider-horizontal {
  margin: 10px 0;
}
.order-list {
  padding: 20px 15px;
  margin-top: 20px;
  margin-bottom: 30px;
}

.list-mode table {
  width: 100%;
  margin: 0 0 20px 0;
}
.list-mode th {
  text-align: left;
  font-weight: 500;
  background-color: rgba(0, 0, 0, 0.06);
  font-size: 13px;
  padding: 10px;
}
.list-mode td {
  font-size: 12px;
  padding: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}
.list-mode tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.01);
}


.card-mode table {
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  border-left: 1px solid rgba(0, 0, 0, 0.06);
  margin-bottom: 15px;
}
.card-mode th {
  font-size: 16px;
  text-align: left;
  font-weight: 500;
  background-color: #FAFAFA;
}
.card-mode th strong {
  font-size: 14px;
}
.card-mode th, .card-mode td {
  padding: 10px;
  border-right: 1px solid rgba(0, 0, 0, 0.06);
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}
.card-mode td {
  width: 25%;
  font-size: 13px;
}
.card-mode .label {
  font-size: 13px;
  color: rgba(0,0,0,0.6)
}

.grey_order, .grey_order a, .grey_order .warning-color {
  color: #676f86;
}

.list-mode a:hover,.card-mode a:hover {
  text-decoration-line: underline;
}

table.add_ticket {
  width: 100%;
}
table.add_ticket td,th {
  padding: 10px 5px 5px 5px;
  text-align: left;
}

.tip {
  font-weight: normal;
  font-size: 13px;
}
</style>