<template>
  <table class="stay-info">
    <tr>
      <td>
        <label>{{ $t('date_before_modification') }}</label>
        <a-range-picker disabled :value="oldCheckInOut" :format="checkInOutFormat">
          <template #suffixIcon>
            <span style="word-break:keep-all;white-space:nowrap;">
              {{ $tc('night_unit', oldNights, {'n': oldNights}) }}
            </span>
          </template>
        </a-range-picker>
      </td>
    </tr>
    <tr>
      <td>
        <label>{{ $t('date_after_modification') }}</label>
        <a-range-picker :inputReadOnly="true" :allowClear="false" :disabledDate="disabledDate" :format="checkInOutFormat" v-model:value="newCheckInOut" @change="dateChange">
          <template #suffixIcon>
            <span style="word-break:keep-all;white-space:nowrap;">
              {{ $tc('night_unit', newNights, {'n': newNights}) }}
            </span>
          </template>
        </a-range-picker>
      </td>
    </tr>
  </table>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "StayInfoPicker",
  props: {
    checkin: Number,
    checkout: Number
  },
  data(){
    let oldCheckInOut = [dayjs.unix(this.checkin).endOf("days"),dayjs.unix(this.checkout).endOf("days")]
    let newCheckInOut = [oldCheckInOut[0], oldCheckInOut[1]]

    const disabledDate = current => {
      return current < dayjs().add(-1, 'day').endOf('day');
    };

    return {
      oldCheckInOut,
      newCheckInOut,
      disabledDate,
      checkInOutFormat: 'YYYY-MM-DD(ddd)',
    }
  },
  computed: {
    oldNights() {
      return this.oldCheckInOut[1].diff(this.oldCheckInOut[0], 'days')
    },
    newNights() {
      return this.newCheckInOut[1].diff(this.newCheckInOut[0], 'days')
    },
  },
  methods:{
    dateChange(dates) {
      if(!dates[0].isSame(this.oldCheckInOut[0]) || !dates[1].isSame(this.oldCheckInOut[1])) {
        let d1 = '[' + this.oldCheckInOut[0].format('YYYY-MM-DD') + ' ~ ' + this.oldCheckInOut[1].format('YYYY-MM-DD') + ']'
        let d2 = '[' + dates[0].format('YYYY-MM-DD') + ' ~ ' + dates[1].format('YYYY-MM-DD') + ']'
        //let stayInfo = '入住日期由 ' + d1 + ' 修改为 ' + d2
        let stayInfo = this.$tc('checkin_date_change_from_to', {'from': d1, 'to': d2})
        this.$emit('valueChange', stayInfo)
      }
    }
  }
}
</script>

<style scoped>
table.stay-info { width: 100%; background-color: #1414140a; }
table.stay-info td { padding: 5px 0 5px 10px}
table.stay-info label { margin-right: 15px}
</style>