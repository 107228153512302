<template>
  <table class="srp-table">
    <tr>
      <td>
        <a-radio-group v-model:value="checkinTimeText" @change="change">
          <a-radio :value="$t('early_checkin')" @click="clickCheckinTimeText">{{$t('early_checkin')}}</a-radio>
          <a-radio :value="$t('late_checkin')" @click="clickCheckinTimeText">{{$t('late_checkin')}}</a-radio>
        </a-radio-group>
        <a-select v-if="checkinTimeText !== null" v-model:value="checkinTime" :options="checkinTimeOptions" @change="change" style="width:200px" size="small">
        </a-select>
      </td>
    </tr>
    <tr>
      <td>
        <a-radio-group v-model:value="bedText" @change="change">
          <a-radio :value="$t('double_room')" @click="clickBedText">{{$t('double_room')}}</a-radio>
          <a-radio :value="$t('twin_room')" @click="clickBedText">{{$t('twin_room')}}</a-radio>
        </a-radio-group>
      </td>
    </tr>
    <tr>
      <td>
        <a-checkbox-group v-model:value="checkedArr" @change="change">
          <a-checkbox :value="$t('higher_floor_room')">{{$t('higher_floor_room')}}</a-checkbox>
          <a-checkbox :value="$t('quiet_room')">{{$t('quiet_room')}}</a-checkbox>
          <a-checkbox :value="$t('honeymoon_arrangement_marriage')">{{$t('honeymoon_arrangement_marriage')}}</a-checkbox>
        </a-checkbox-group>
      </td>
    </tr>
    <tr>
      <td>
        <a-space>
          <a-checkbox-group v-model:value="continueCheckinArr" @change="change">
            <a-checkbox :value="$t('continue_live_room')">{{$t('continue_live_room')}}</a-checkbox>
          </a-checkbox-group>
          <a-input v-if="continueCheckinArr.length === 1" v-model:value="continueCheckinNo" @change="change" :placeholder="$t('continue_live_room_remark')" style="width:500px" size="small" />
        </a-space>
      </td>
    </tr>
    <tr>
      <td>
        <a-space>
          <a-checkbox-group v-model:value="otherArr" @change="change">
            <a-checkbox :value="$t('other_requirements')">{{$t('other_requirements')}}</a-checkbox>
          </a-checkbox-group>
          <a-input v-if="otherArr.length === 1" v-model:value="other" @change="change" style="width:500px" size="small" />
        </a-space>
      </td>
    </tr>
  </table>
</template>

<script>
import dayjs from "dayjs";
import {isEmpty} from "@/utils/util";

export default {
  name: "SpecialRequestPicker",
  props: {
    checkin: Number,
  },
  data(){
    return {
      checkinTimeText: null,
      checkinTime: '',
      checkinTimeOptions: [],
      bedText: '',
      checkedArr: [],
      continueCheckinArr: [],
      continueCheckinNo: '',
      otherArr: [],
      other: '',
    }
  },
  watch: {
    checkinTimeText: function (value, value1) {
      if(value === value1)
        return

      this.checkinTime = ''
      let checkinDate = dayjs.unix(this.checkin)
      this.checkinTimeOptions = [{ value: '', label: this.$t('checkin_time') }]
      if(value === this.$t('early_checkin')) {
        for(let h = 0;h < 14;h++) {
          let value = checkinDate.add(h, 'hour').format('YYYY-MM-DD HH:00') + '-' + checkinDate.add(h + 1, 'hour').format('HH:00')
          this.checkinTimeOptions.push({value: value, label: value})
        }
      }else if(value === this.$t('late_checkin')) {
        checkinDate = checkinDate.add(20, 'hour')
        for(let h = 0;h < 12;h++) {
          let value = checkinDate.add(h, 'hour').format('YYYY-MM-DD HH:00') + '-' + checkinDate.add(h + 1, 'hour').format('HH:00')
          this.checkinTimeOptions.push({value: value, label: value})
        }
      }
    },
  },
  methods: {
    clickCheckinTimeText(e){
      if(e.target.checked && e.target.value === this.checkinTimeText){
        this.checkinTimeText = null
      }
    },
    clickBedText(e){
      if(e.target.checked && e.target.value === this.bedText){
        this.bedText = null
      }
    },
    change() {
      let srArr = [];
      if (!isEmpty(this.checkinTimeText) && !isEmpty(this.checkinTime))
        srArr.push(this.checkinTimeText + ':' + this.checkinTime)
      if (!isEmpty(this.bedText))
        srArr.push(this.bedText)
      if (this.checkedArr?.length > 0)
        srArr = srArr.concat(this.checkedArr)
      if (this.continueCheckinArr.length > 0)
        srArr.push(this.continueCheckinArr[0] + (!isEmpty(this.continueCheckinNo) ? ':' + this.continueCheckinNo + '(原单号)' : ''))
      if (this.otherArr.length > 0 && !isEmpty(this.other))
        srArr.push(this.otherArr[0] + ':' + this.other)

      this.$emit('valueChange', srArr)
    }
  }
}
</script>

<style scoped>
.srp-table { width: 100%}
.srp-table td { padding: 5px 5px;height:35px}
</style>